import React from "react"
export default function SelectPayment({ selectPayment, setPaymentTime }) {
  return (
    <div className="input-with-label text-left form-group ">
      <div className="radio-o">
        <span>Pay now</span>
        <input
          className="form-control"
          id="toPay"
          type="radio"
          value={true}
          onChange={setPaymentTime}
          checked={selectPayment}
        />
      </div>
      <div className="radio-o">
        <span>Pay Later</span>
        <input
          className="form-control"
          id="toPay"
          type="radio"
          value={""}
          onChange={setPaymentTime}
          checked={!selectPayment}
        />
      </div>
    </div>
  )
}
